import Vue from 'vue';
import qs from 'query-string';
export class ApiService {
  setAccessToken(accessToken) {
    Vue.axios.defaults.headers.common['Authorization'] = accessToken;
  }
  loginAuthTv(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/tv/login/auto', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  loginTv(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/loginTv', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  registerTv(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/registerTv', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  createUserName(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/user/name/create', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  checkUserName(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/user/name/check', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  logOut(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/logout', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getTerms(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/terms', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  checkEmail(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/email/check', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  loginAuto(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/login/auto', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  login(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/login', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }

  loginTmp(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/common/check/password', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }  

  register(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/register', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getProfile(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/profile', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  reissueToken(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/reissue/token', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  editProfile(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/profile/edit', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  withdrawalProfile(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/profile/withdrawal', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  checkToken(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/auth/check/token', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getHotMarketplace(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/home/hot/marketplace', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getRecentActivities(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/home/recent/activities', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getLiveDrop(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/home/drop/live', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getSubmitRequest(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/home/help/submit', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getDropArtworkPreview(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/drops/artwork/preview', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getDropEditionItems(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/drops/edition/items', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getDropArtworkDetail(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/drops/artwork/detail', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getDropCurrentStatus(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/drops/status', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getLiveDropDetail(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/drops/detail', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUpcomingAlarm(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/drops/upcoming/alarm', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getDropList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/drops/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUpcomingDetail(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/drops/upcoming/detail', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getEndedDetail(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/drops/ended/detail', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  setUpcomingReminder(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/drops/upcoming/reminder', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  setArtworkFavorites(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/drops/artwork/favorites', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  downloadImage(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/marketplace/download', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getEditionDetail(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/marketplace/detail', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  setEditionFavorites(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/marketplace/favorites', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getArtworkEditionStatus(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/marketplace/edition/status', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getArtworkEditionList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/marketplace/edition/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getArtworkDetail(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/marketplace/artwork/detail', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  changeSaleMethod(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/marketplace/sale/method/change', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getMarketPlaceItemList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/marketplace/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getMyOtherMarketUrl(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/collection/list/play', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }  
  getMyCollectionList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/collection/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getMyPlayList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/collection/list/play', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  deletFavorites(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/collection/favorites/delete', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  confirmFamilyRequest(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/family/confirm', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  callbackWithdraw(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/withdraw', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getQrUrlForMarketPurchase(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/qr/market/purchase', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  callbackGetQrUrlForMarketPurchase(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/market/purchase/callback', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUserReminderList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/reminder/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getDropEthRegister(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/qr/drop/transaction/register', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getMarketEthRegster(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/ethereum/market/register', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }  
  getMarketEthCallback(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/ethereum/market/cmpltYn', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getMarketEthPermissionCallback(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/qr/drop/permission/cmpltYn', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }  
  getMarketEthPurchase(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/ethereum/market/purchase', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }

  getMarketEthPriceSetting(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/qr/sale/price/setting', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getMarketEthStatusRegister(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/qr/status/orders/register', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }


  getMarketEthListing(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/qr/status/orders/sign', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }

  getMarketEthCancelRegister(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/qr/status/orders/cancel/register', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }  

  getQrUrlForSalePriceSetting(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/qr/sale/price/setting', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }

  getDropEthCallBack(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/qr/drop/transaction/cmpltYn', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getDropPurchaseCmpltYn(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/drop/purchase/cmpltYn', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getMarketPurchaseCmpltYn(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/market/purchase/cmpltYn', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  modifyMyAvatarImage(req) {
    const formData = new FormData();
    if (req.profileImage != null) {
      formData.append('profileImage', req.profileImage);
    }
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/profile/modify', formData).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        if (res.status === 401) {
          // emitter.instance.emit('unauthorized');
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  deleteMyFavorite(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/favorites/delete', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getMyFavoriteList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/favorites', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  removeUserFamily(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/family/remove', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  addUserFamily(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/family/add', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUserFamilyList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/family/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUserActivityHis(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/activity/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  setUserFollowing(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/following/save', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUserFollowings(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/following', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUserFollowers(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/followers', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUserProfile(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/profile', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getArtistProfile(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/artist/profile', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  connectWallet(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/connect/wallet', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  callbackConnectWallet(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/connectWallet/callback', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getQrUrlForDropPurchase(req) {
  return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/qr/drop/purchase', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  callbackGetQrUrlForDropPurchase(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/drop/purchase/callback', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  callbackGetQrUrlForCreateSale(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/create/sale/callback', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  callbackGetQrUrlForCancelSale(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/cancel/sale/callback', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getPriceSettingCmpltYn(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/price/setting/cmpltYn', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  disconnectWallet(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/disconnect/wallet', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }  
  getNftBalance(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/nftBalance', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getNotificationSetting(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/notification', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  modifyNotificationSetting(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/notification/modify', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  addUserRestore(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/restore', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getUsdcAssociation(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/usdc/association', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getMyWallet(req) {
    const url = '/api/v1/account/wallet?' + qs.stringify(req);
    return new Promise((resolve, reject) => {
      Vue.axios.get(url).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      });
    });
  }
  getQrUrlForConnect(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/account/qr/connect', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getTrendingKeywords(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/search/keywords/trending', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }


  getMostKeywords(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/search/keywords/most', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }  

  getCompleteKeywords(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/search/keywords/complete', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getSearchResults(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/search/results', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getFaqDetail(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/support/faq/detail', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getFaqList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/support/faq/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getFaqSearchList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/support/faq/search/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getReminderList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/support/reminder/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getNoticeDetail(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/support/notice/detail', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getNoticeList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/support/notice/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getStatus(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/common/status', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  getNotificationList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/common/notification/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  //get User Zipcode
  getZipCode(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/zipcode', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  //zpcd를 받아 해당되는 city, stat, tax info
  getZipCodeCityList(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/zipcode/city/list', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  //city, zpcd를 받아 유저 정보 Update
  setZipCodeSave(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/user/zipcode/city/save', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }  
  testRedis(req) {
    const url = '/api/v1/mock/redis/test?' + qs.stringify(req);
    return new Promise((resolve, reject) => {
      Vue.axios.get(url).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      });
    });
  }
  testResource(req) {
    const url = '/api/v1/mock/resource/test?' + qs.stringify(req);
    return new Promise((resolve, reject) => {
      Vue.axios.get(url).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      });
    });
  }
  fileDelete(req) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/file/delete', req).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
  fileUpload(req) {
    const formData = new FormData();
    if (req.file != null) {
      formData.append('file', req.file);
    }
    formData.append('resourceType', req.resourceType ?? '');
    return new Promise((resolve, reject) => {
      Vue.axios.post('/api/v1/file/upload', formData).then(res => {
        if (res.status === 200) {
          resolve(res.data);
          return;
        }
        if (res.status === 401) {
          // emitter.instance.emit('unauthorized');
          return;
        }
        console.error(res.status, res.statusText);
        reject(null);
      })
      .catch((err) => {
        reject(err);
      })
    });
  }
}
const _api = new ApiService();
ApiService.install = function (Vue /* options?: any */) {
  Vue.api = _api;
  Object.defineProperties(Vue.prototype, {
    $api: {
      get() {
        return _api;
      },
    },
    api: {
      get() {
        return _api;
      },
    },
  });
};

Vue.use(ApiService);

export default ApiService;
