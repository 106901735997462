import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import DropsIndexView from '../views/drops/DropsView.vue';
import DropsCurationView from '@/views/drops/DropsCurationView';
import DropsDetailView from '@/views/drops/DropsDetailView';
import DropsDetailPreView from '@/views/drops/DropsDetailPreView';
import MyCollectionView from '@/views/mycollection/MyCollectionView';
import MyCollectionModeView from '@/views/mycollection/MyCollectionModeView';
import MarketplaceView from '@/views/marketplace/MarketplaceView';
import MarketplaceDetailView from '@/views/marketplace/MarketplaceDetailView';
import FavoritesView from '@/views/mylist/FavoritesView';
import WalletView from '@/views/mylist/WalletView';
import ActivitiesView from '@/views/mylist/ActivitiesView';
import NotificationView from '@/views/mylist/NotificationView';
import MyProfileArtistView from '@/views/mylist/MyProfileArtistView';
import PurchaseView from '@/views/PurchaseView';
import SearchResultView from '@/views/SearchResultView';
import ProfileArtistView from '@/views/mylist/ProfileArtistView';
import OtherProfileView from '@/views/mylist/OtherProfileView';
import TermsView from '@/views/etc/TermsView';
import NoticeBoardView from '@/views/etc/NoticeBoardView';
import NoticeDetailView from '@/views/etc/NoticeDetailView';
import HelpCenterDetailView from '@/views/etc/HelpCenterDetailView';
import HelpCenterBoardView from '@/views/etc/HelpCenterBoardView';
import MyProfileUserView from '@/views/mylist/MyProfileUserView';
import FamilyMembersView from '@/views/mylist/FamilyMembersView';
import DeleteAccountView from '@/views/mylist/DeleteAccountView';
import HelpCenterBoardResult from '@/views/etc/HelpCenterBoardResult';
import Error500 from '@/views/error/Error500';
import Error404 from '@/views/error/Error404';
import NetworkError from '@/views/error/NetworkError';
import UnKnown from '@/views/error/UnKnown';
import openSourceLicenseInfo from '@/views/openSourceLicenseInfo';
import DetailViewer from '@/views/DetailViewer';
import WalletChangeView from '@/views/mylist/WalletChangeView';
import TemporaryLogin from '@/views/error/TemporaryLogin';
import AboutUsView from '@/views/etc/AboutUsView';

import store from '@/store/index';
import { ApiService } from '@/service/apiservice';
const api = new ApiService();

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home',
    },
  },
  {
    path: '/drops',
    name: 'drops',
    component: DropsIndexView,
    meta: {
      title: 'Drops',
    },
  },
  {
    path: '/drops/curation',
    name: 'drops-curation',
    component: DropsCurationView,
    meta: {
      title: 'Drops Curation',
    },
  },
  {
    path: '/drops/detail',
    name: 'dropsDetail',
    component: DropsDetailView,
    meta: {
      title: 'Drops Detail',
    },
  },
  {
    path: '/drops/preview',
    name: 'dropsDetailPreview',
    component: DropsDetailPreView,
    meta: {
      title: 'Drops Preview',
    },
  },
  {
    path: '/marketplace',
    name: 'marketplace',
    component: MarketplaceView,
    meta: {
      title: 'Marketplace',
    },
  },
  {
    path: '/marketplace/detail',
    name: 'marketplaceDetail',
    component: MarketplaceDetailView,
    meta: {
      title: 'Marketplace Detail',
    },
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: PurchaseView,
    meta: {
      title: 'Purchase',
    },
  },
  {
    path: '/collections',
    name: 'myCollection',
    component: MyCollectionView,
    meta: {
      isFooterHide: true,
      requireAuth: true,
      title: 'MyCollection',
    },
  },
  {
    path: '/collections/mode',
    name: 'myCollectionMode',
    component: MyCollectionModeView,
    meta: {
      title: 'MyCollection > Mode',
    },
  },
  {
    path: '/mylist/favorites',
    name: 'favorites',
    component: FavoritesView,
    meta: {
      requireAuth: true,
      title: 'My Account > Favorites',
    },
  },
  {
    path: '/mylist/wallet',
    name: 'wallet',
    component: WalletView,
    meta: {
      requireAuth: true,
      title: 'My Account > Wallet',
    },
  },
  {
    path: '/mylist/wallet/changed',
    name: 'walletChanged',
    component: WalletChangeView,
    meta: {
      requireAuth: true,
      title: 'My Account > Wallet Change',
    },
  },
  {
    path: '/mylist/activities',
    name: 'activities',
    component: ActivitiesView,
    meta: {
      requireAuth: true,
      title: 'My Account > Activities',
    },
  },
  {
    path: '/mylist/settings',
    name: 'settings',
    component: NotificationView,
    meta: {
      requireAuth: true,
      title: 'My Account > Settings',
    },
  },
  {
    path: '/mylist/myartist',
    name: 'myProfileArtist',
    component: MyProfileArtistView,
    meta: {
      requireAuth: true,
      title: 'My Account > Artist',
    },
  },
  {
    path: '/mylist/artist/:mbrNo',
    name: 'ProfileArtist',
    component: ProfileArtistView,
    meta: {
      title: 'Artist',
    },
  },
  {
    path: '/mylist/user',
    name: 'myProfileUser',
    component: MyProfileUserView,
    meta: {
      requireAuth: true,
      title: 'My Account > User',
    },
  },
  {
    path: '/mylist/other/:mbrNo',
    name: 'otherProfile',
    component: OtherProfileView,
    meta: {
      title: 'OtherProfile',
    },
  },
  {
    path: '/search/:keyword',
    name: 'search',
    component: SearchResultView,
    meta: {
      title: 'Search Result',
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: TermsView,
    meta: {
      title: 'Privacy',
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
    meta: {
      title: 'Terms',
    },
  },
  {
    path: '/notice',
    name: 'notice',
    component: NoticeBoardView,
    meta: {
      title: 'Notice',
    },
  },
  {
    path: '/notice/detail',
    name: 'noticeDetail',
    component: NoticeDetailView,
    meta: {
      title: 'Notice > Detail',
    },
  },
  {
    path: '/help',
    name: 'helpCenter',
    component: HelpCenterBoardView,
    meta: {
      title: 'Help Center',
    },
  },
  {
    path: '/help/detail',
    name: 'helpCenterDetail',
    component: HelpCenterDetailView,
    meta: {
      title: 'Help Center > Detail',
    },
  },
  {
    path: '/help/result/:keyword',
    name: 'helpCenterResult',
    component: HelpCenterBoardResult,
    meta: {
      title: 'Help Center > Result',
    },
  },
  {
    path: '/family',
    name: 'familyMembers',
    component: FamilyMembersView,
    meta: {
      title: 'Family',
    },
  },
  {
    path: '/mylist/delete',
    name: 'deleteAccount',
    component: DeleteAccountView,
    meta: {
      requireAuth: true,
      title: 'My Account > Delete Account',
    },
  },
  {
    path: '/404',
    name: 'error404',
    component: Error404,
    meta: {
      isFooterHide: true,
      isError: true,
      title: 'Error404',
    },
  },
  {
    path: '/500',
    name: 'error500',
    component: Error500,
    meta: {
      isFooterHide: true,
      isError: true,
      title: 'Error500',
    },
  },
  {
    path: '/network',
    name: 'network',
    component: NetworkError,
    meta: {
      isFooterHide: true,
      isError: true,
      title: 'Network',
    },
  },
  {
    path: '/unknown',
    name: 'unknown',
    component: UnKnown,
    meta: {
      isFooterHide: true,
      isError: true,
      title: 'Unknown',
    },
  },
  {
    path: '/openSourceLicenseInfo',
    name: 'openSourceLicenseInfo',
    component: openSourceLicenseInfo,
    meta: {
      isHeaderHide: true,
      isFooterHide: true,
      isError: true,
      title: 'openSourceLicenseInfo',
    },
  },
  {
    path: '/viewer',
    name: 'viewer',
    component: DetailViewer,
    meta: {
      layout: 'EmptyLayout',
      title: 'Viewer',
    },
  },
  {
    path: '/loginProcess',
    name: 'LoginProcess',
  },
  {
    path: '/editProcess',
    name: 'EditProcess',
  },
  {
    path: '/withdrawProcess',
    name: 'WithdrawProcess',
  },
  {
    path: '/temporarylogin',
    name: 'temporarylogin',
    component: TemporaryLogin,
    meta: {
      temporaryAuth: true,
      title: 'Temporarylogin',
    },
  },
  {
    path: '/about-us',
    name: 'about_us',
    component: AboutUsView,
    meta: {
      title: 'About Us',
    },
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'is_active',
  linkExactActiveClass: 'is_current_active',
  scrollBehavior: () => ({ y: 0 }),
});
router.beforeEach(async (to, from, next) => {
  if (to.query.accessFromCes) {
    sessionStorage.setItem('cesLink', to.fullPath);
  }
  if (to.query.redirect) {
    sessionStorage.setItem('redirect', to.path);
  }

  if (to.name == 'LoginProcess') {
    let params = {
      authorizationCode: to.query.code,
      deviceType: Vue.prototype.$isMobile() ? 3 : 2,
      empUserNumber: to.query.user_number,
      //redirectUri: process.env.VUE_APP_SERVICE_HOST + '/loginProcess',
      redirectUri: window.location.origin + '/loginProcess',
      empdata: to.query,
    };
    console.log('new emp Test value : ', to.query);
    window.opener.loginProc(params);
    window.close();
  }

  if (to.name == 'EditProcess') {
    window.opener.editProc();
    window.close();
  }

  if (to.name == 'WithdrawProcess') {
    window.opener.withdrawProc();
    window.close();
  }

  if (
    store.getters['appStore/accessToken'] &&
    sessionStorage.getItem('refreshTime')
  ) {
    if (!to.matched.some(record => record.meta.isError)) {
      await api
        .getProfile({})
        .then(async res => {
          if (res.code === 0) {
            const profile = {
              artistDescription: res.artistDescription,
              facebookUrl: res.facebookUrl,
              twitterUrl: res.twitterUrl,
              instagramUrl: res.instagramUrl,
              webSiteUrl: res.webSiteUrl,
            };
            await store.commit('userStore/redDotYn', res.redDotYn);
            await store.commit('userStore/userName', res.userName);
            await store.commit('userStore/userType', res.userType);
            await store.commit('userStore/mbrNo', res.mbrNo);
            await store.commit('userStore/email', res.email);
            await store.commit('userStore/svcJoinYn', res.svcJoinYn);
            await store.commit('userStore/walletYn', res.walletYn);
            await store.commit('userStore/stateYn', res.stateYn);
            await store.commit('userStore/followers', res.followerCount);
            await store.commit('userStore/following', res.followingCount);
            await store.commit('userStore/zpcd', res.zpcd);
            await store.commit('userStore/city', res.city);
            await store.commit('userStore/stat', res.stat);
            await store.commit('userStore/wlltType', res.wlltType);
            await store.commit('userStore/familyInfos', res.familyInfos);
            await store.commit('userStore/profileDetails', profile);
            await store.commit(
              'userStore/ethWallet',
              res.walletInfos
                ? res.walletInfos.find(v => v.bcNwTp === '20')
                : {},
            );
            await store.commit(
              'userStore/hdrWallet',
              res.walletInfos
                ? res.walletInfos.find(v => v.bcNwTp === '10')
                : {},
            );
            await store.commit(
              'userStore/profileInfos',
              res.profileInfos ? res.profileInfos : [],
            );
            await store.commit(
              'userStore/profileCoverInfos',
              res.profileCoverInfos ? res.profileCoverInfos : [],
            );
          }
        })
        .catch(e => {
          console.log('===== getProfile ERROR ===== ', e);
        });
    }
  }
  //페이지 진입시 임시비밀번호 입력
  if (JSON.parse(process.env.VUE_APP_TEMP_PASSWORD)) {
    if (!to.matched.some(record => record.meta.temporaryAuth)) {
      if (sessionStorage.getItem('nftKey') !== 'Y') {
        return next({
          path: '/temporarylogin',
        });
      }
    }
  }

  if (to.matched.some(record => record.meta.requireAuth)) {
    if (!store.getters['appStore/accessToken']) {
      if (to.name === 'myCollection') {
        next(false);
      } else {
        next('/');
      }
    } else {
      if (sessionStorage.getItem('refreshTime')) {
        next();
      } else {
        next('/');
      }
    }
  } else {
    next();
  }

  // Follower, Following Modal Router 쪽 Duplicate Error 발생하여 추가함 2022-07-27 윤정현
  const originalPush = VueRouter.prototype.replace;
  VueRouter.prototype.replace = function replace(location) {
    return originalPush.call(this, location).catch(err => {
      if (err.name !== 'NavigationDuplicated') throw err;
    });
  };
});
router.afterEach(to => {
  const title =
    to.meta.title === undefined
      ? `LG Art Lab | LG's fine art NFT marketplace`
      : to.meta.title + ` | LG's fine art NFT marketplace`;
  Vue.nextTick(() => {
    document.title = title;
  });
});
export default router;
