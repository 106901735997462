<template>
  <main id="contents" class="drops_page list_page">
    <h1 class="page_ttl is_blind">Drops</h1>
    <div class="set_inner">
      <section class="list_sec live_sec" v-if="liveInfos && liveInfos.dropsId">
        <header class="sec_head">
          <div class="ttl_area">
            <h2 class="ttl"><b>Live</b> Drops</h2>
          </div>
        </header>
        <div class="sec_body">
          <div class="img_wrap clickable" @click="goCuration(dropsId)">
            <img
              :src="liveDropsMainImg"
              :width="980"
              :alt="'Capture of Artwork for the ' + liveInfos.title"
            />
          </div>
          <div class="info_wrap">
            <div class="time_group clearFix">
              <CountDown
                v-if="liveInfos.dropsEndDate"
                :date-time="liveInfos.dropsEndDate"
              />
            </div>
            <div class="ttl_area artist_info">
              <h3 class="ttl">
                <b>{{ liveInfos.artistName }}</b>
              </h3>
              <p class="desc">{{ liveInfos.artistDescription }}</p>
            </div>
            <div class="ttl_area drops_info">
              <h4 class="ttl">
                <b>{{ liveInfos.title }}</b>
              </h4>
              <p class="desc">{{ liveInfos.artworkDescription }}</p>
            </div>
            <div class="btn_area">
              <button
                class="btn w_s h_l strong"
                @click="goCuration(dropsId)"
                :title="'artwork named the ' + liveInfos.title"
              >
                View Details
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="list_sec upcoming_sec" v-if="upcomingInfos.length">
        <header class="sec_head">
          <div class="ttl_area">
            <h2 class="ttl"><b>Upcoming</b> Drops</h2>
          </div>
        </header>
        <div class="sec_body">
          <div class="upcoming_flow_wrap">
            <ul class="flow_list" v-if="upcomingInfos">
              <li
                v-for="(flow, index) in upcomingInfos"
                :key="flow.artworkId + index"
                class="flow_node"
              >
                <div class="flow_item">
                  <div class="date_info">
                    <span
                      :class="{
                        is_pc_date: !isMob,
                      }"
                    >
                      {{
                        setUpcomingDateStr(
                          flow.dropsId,
                          flow.dropsStartDate,
                          !isMob,
                        )
                      }}
                    </span>
                  </div>
                  <div
                    class="item_head clickable"
                    @click="goCuration(flow.dropsId)"
                  >
                    <div class="thumb_area">
                      <button>
                        <img
                          v-if="flow.mimeType === 'video/mp4'"
                          :srcSet="`${flow.videoThumbInfos[0].resUrl} 768w,
                          ${flow.videoThumbInfos[0].resUrl}`"
                          :src="flow.videoThumbInfos[0].resUrl"
                          :width="598"
                          :alt="'Capture of Artwork for the ' + flow.title"
                        />
                        <img
                          v-else-if="
                            flow.mimeType === 'image/jpeg' && flow.imageInfos[0]
                          "
                          :srcSet="`${flow.imageInfos[0].resUrl} 768w,
                          ${flow.imageInfos[0].resUrl}`"
                          :src="flow.imageInfos[0].resUrl"
                          :width="598"
                          :alt="'Capture of Artwork for the ' + flow.title"
                        />

                        <img
                          v-else
                          :src="require('@/assets/img/img_illust_nodata.png')"
                          alt="nodata"
                        />
                      </button>
                    </div>
                  </div>
                  <div class="item_body">
                    <div class="ttl_area">
                      <h3 class="ttl">
                        <b>{{ flow.artistName }}</b>
                      </h3>
                      <p class="desc">
                        <b>{{ flow.artistDescription }}</b>
                      </p>
                    </div>
                    <div class="btn_area">
                      <button
                        class="btn basic w_xl h_l"
                        :class="{
                          is_active: alarmList.indexOf(flow.dropsId) !== -1,
                        }"
                        @click="setAlarm(flow.dropsId)"
                      >
                        <IcAlarm
                          v-if="alarmList.indexOf(flow.dropsId) === -1"
                          stroke-color="#000"
                          class="ic_alarm"
                          :alarmList.sync="alarmList"
                        />
                        <IcCheck
                          v-else
                          class="ic_check"
                          :alarmList.sync="alarmList"
                        />
                        <span>Get Notification</span>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section
        class="list_sec ended_sec"
        v-if="endedInfos && endedInfos.length"
      >
        <header class="sec_head">
          <div class="ttl_area">
            <h2 class="ttl"><b>Ended</b> Drops</h2>
          </div>
        </header>
        <div class="sec_body">
          <EndedSlide :item-data="endedInfos" :slide-opt="endedSlidOpt" />
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import IcAlarm from '@/components/ic/IcAlarm';
import IcCheck from '@/components/ic/IcCheck';
import EndedSlide from '@/components/drops/EndedSlide';
import CountDown from '@/components/drops/CountDown';
import { mapGetters } from 'vuex';

export default {
  name: 'DropsView',
  components: { EndedSlide, IcCheck, IcAlarm, CountDown },
  data() {
    return {
      isMob: false,
      loginAlert: {
        type: 'warn_2',
        desc: 'Please sign in.',
        success: 'Sign in',
      },
      notiAlert: {
        type: 'warn_3',
        desc: 'Your Reminder Email Setting is off. If you’d like to get Notification email, click OK.',
        success: 'OK',
      },
      reminderYn: '',
      liveInfos: [],
      liveDropsMainImg: '',
      liveInfosDropsId: '',
      upcomingInfos: [],
      endedInfos: [],
      alarmList: [],
      dropsId: '',
      endedSlidOpt: {
        loop: false,
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 30,
        pagination: {
          el: '.edition_slide_wrap .pagination_area',
        },
        navigation: {
          prevEl: '.edition_slide_wrap .slide_nav_area .prev',
          nextEl: '.edition_slide_wrap .slide_nav_area .next',
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            slidesPerGroup: 1,
          },
          280: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
        },
      },
      apiFailAlert: {
        type: 'warn',
        desc: "We're sorry, something went wrong. Transaction failed. Something is wrong with your wallet. Please try again later.",
      },
    };
  },

  computed: {
    ...mapGetters({
      accessToken: 'appStore/accessToken',
      refreshToken: 'appStore/refreshToken',
    }),
  },
  async mounted() {
    /*
    //2023-01-19 CES 안내팝업 비활성화
    let accessFromCes = this.$route.query.accessFromCes || false;
    if (JSON.parse(accessFromCes) === true) {
      this.$root.$emit('commonModal');
    }
    */
    this.setDropList();
    this.isMob = this.$isMobile();
  },
  methods: {
    async setDropList() {
      await this.api.getDropList({}).then(res => {
        if (res.code === 0) {
          if (Array.isArray(res.liveInfos) && res.liveInfos.length) {
            this.dropsId = res.liveInfos[0].dropsId;
            this.liveInfos = res.liveInfos[0];

            this.liveDropsMainImg = this.liveInfos.imageInfos.length
              ? this.liveInfos.imageInfos[0].resUrl
              : require('@/assets/img/img_illust_nodata.png');
          }
          this.upcomingInfos = res.upcomingInfos;

          this.upcomingInfos.map(x => {
            if (x.reminderYn === 'Y') {
              this.alarmList.push(x.dropsId);
            }
          });
          this.endedInfos = res.endedInfos;
        }
      });
    },
    alarmCheck(id) {
      const checkIdx = this.alarmList.indexOf(id);
      if (checkIdx !== -1) {
        this.alarmList.splice(checkIdx, 1);
        return 'N';
      }
      this.alarmList.push(id);
      return 'Y';
    },

    async setAlarm(id) {
      if (!this.accessToken) {
        this.$root.$emit('openAlert', this.loginAlert);
      } else {
        await this.api.getNotificationSetting({}).then(res => {
          if (res.code === 0) {
            if (res.reminderYn === 'N') {
              this.$root.$emit('openAlert', this.notiAlert);
            } else {
              let reminderYn = this.alarmCheck(id);
              let params = {
                dropId: id,
                reminderYn: reminderYn,
              };
              this.api.setUpcomingReminder(params).then(res => {
                if (res.code === 0) {
                  console.log('알림 등록 완료');
                } else {
                  this.alarmCheck(id);
                  this.$root.$emit('openAlert', this.apiFailAlert);
                }
              });
            }
          }
        });
      }
    },

    goCuration(v) {
      this.$router.push({
        name: 'drops-curation',
        query: { dropsId: v },
      });
    },
  },
};
</script>
<style scoped>
@import '../../assets/css/drops.css';
.clickable {
  cursor: pointer;
}
.date_info {
  text-align: left;
}
.is_pc_date {
  white-space: pre-line;
}

/* 2022-08-26 Live 드랍 상태의 카운터 중 해당영역만 margin이 줄어들게 처리 요청 - by 기획자 */
.time_group {
  margin-bottom: 15px !important;
}
@media screen and (min-width: 1025px) {
  .flow_list .flow_item .item_body .desc b {
    -webkit-line-clamp: 5 !important;
  }
}
</style>
