<template>
  <main id="contents" class="footer_page terms_page">
    <div class="set_inner">
      <div class="page_ttl_area">
        <h1 class="page_ttl">About Us</h1>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'AboutUsView',
  components: {},
  data() {
    return {
      isLoading: false,
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
@import '../../assets/css/footer.css';
.footer_page {
  padding-bottom: 6rem;
}
</style>
